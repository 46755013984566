import { graphql } from "gatsby"
import AuthorPage from "../views/Author"

export const query = graphql`
  query Author($slug: String!, $blogSlugs: [String]!) {
    author: contentfulAuthor(slug: { eq: $slug }) {
      name
      bio {
        bio
      }
      photo {
        fixed(width: 128, height: 128) {
          src
        }
      }
    }
    blogs: allContentfulBlogPost(
      sort: { order: DESC, fields: createdOn }
      filter: { slug: { in: $blogSlugs } }
    ) {
      edges {
        node {
          slug
          title
          excerpt {
            raw
          }
          postImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default AuthorPage
