import React from "react"
import { Avatar, Flex, Box } from "@chakra-ui/core"
import MaxWidthGrid from "../components/Layout/MaxWidthGrid"
import PageContainer from "../components/PageContainer"
import Heading from "../components/typography/Heading"
import Body from "../components/typography/Body"
import SEO from "../components/boilerplate/seo"
import { AuthorQuery } from "../../graphql-types"
import ArticleList from "../components/ArticleList"
import { bp } from "../utils/MediaQueries"

type AuthorPageProps = {
  data: AuthorQuery
  location: Location
}

export default function AuthorPage({ data, location }: AuthorPageProps) {
  return (
    <PageContainer>
      <SEO
        title={data.author?.name || "Author"}
        description={data.author?.bio?.bio || ""}
        location={location}
      />
      <MaxWidthGrid pt={bp("2rem", "4.5rem")}>
        <Box gridColumn={bp("1/end", "2/14")}>
          <Flex
            w="100%"
            mb={bp("3rem", "4.75rem")}
            flexDir={bp("column", "row")}
            align="center"
          >
            <Avatar
              size="2xl"
              bg={data.author?.photo?.fixed?.src ? "white" : "primary"}
              color="white"
              name={data.author?.name || "N/A"}
              src={data.author?.photo?.fixed?.src}
              mr={bp("0", "3.75rem")}
              mb={bp("2rem", "0")}
            />
            <Flex
              flexDir="column"
              flexGrow={1}
              textAlign={bp("center", "left")}
            >
              <Heading as="h1" size="2" fontWeight="bold" mb="1.25rem">
                {data.author?.name}
              </Heading>
              {data.author?.bio?.bio && (
                <Body color="dawn" maxW="38.125rem">
                  {data.author.bio.bio}
                </Body>
              )}
            </Flex>
          </Flex>
          <ArticleList articles={data.blogs.edges.map((blog) => blog.node)} />
        </Box>
      </MaxWidthGrid>
    </PageContainer>
  )
}
