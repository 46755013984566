import React from "react"
import { Box, BoxProps, SimpleGrid, Flex } from "@chakra-ui/core"
import { useQueryParam, NumberParam } from "use-query-params"
import truncate from "lodash/truncate"

import {
  ContentfulBlogPost,
  ContentfulAsset,
  Maybe,
} from "../../../graphql-types"
import Body from "../typography/Body"
import ArticleBlock from "../ArticleBlock"
import { rawRichTextToString } from "../../utils/richText"
import { FluidObject } from "gatsby-image"
import Pagination from "../Pagination"
import { placeholderImage } from "../../utils"
import ArticleBlockItem from "../ArticleBlockItem"
import { bp } from "../../utils/MediaQueries"

type ArticleListProps = BoxProps & {
  articles: (Pick<ContentfulBlogPost, "excerpt" | "slug" | "title"> & {
    postImage?: Maybe<Pick<ContentfulAsset, "fluid">>
  })[]
  pageSize?: number
  emptyText?: string
  blogLayoutNew?: boolean
}

export default function ArticleList({
  articles: allArticles,
  pageSize = 9,
  emptyText = "No articles available",
  blogLayoutNew = false,
  ...props
}: ArticleListProps) {
  const [page = 1, setPage] = useQueryParam("page", NumberParam)
  const baseZeroPage = page! - 1

  const [latestArticle, ...articles] = allArticles
  const pageCount = Math.ceil(articles.length / pageSize)

  const handleNext = () => {
    setPage(Math.min(page! + 1, pageCount))
  }
  const handlePrev = () => {
    setPage(Math.max(page! - 1, 1))
  }

  return (
    <Box {...props}>
      {!latestArticle && (
        <Body size="lg" color="dawn" mt="1rem" textAlign="center">
          {emptyText}
        </Body>
      )}
      {latestArticle &&
        (!blogLayoutNew ? (
          <ArticleBlock
            tag="Latest"
            title={latestArticle.title!}
            subtext={truncate(
              rawRichTextToString(latestArticle?.excerpt?.raw),
              {
                length: 86,
              }
            )}
            to={`/blogs/bike-advice/${latestArticle.slug}`}
            image={
              (latestArticle.postImage?.fluid ||
                placeholderImage) as FluidObject
            }
            mb="7.5rem"
          />
        ) : (
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
          >
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
              alignContent="flex-start"
              background="#eee"
              width="50%"
              height="450px"
              padding="30px"
            >
              <Body
                as="h2"
                fontSize="18px"
                fontWeight="800"
                textTransform="uppercase"
              >
                Latest
              </Body>
              <Body
                as="h2"
                fontSize="56px"
                fontWeight="bold"
                // maxW="75%"
                lineHeight="66px"
                mt="10px"
                mb="20px"
              >
                Amazing Tricycle For Disabled Adults
              </Body>
              <Body as="p">
                Now, we hear about and get lots of questions about adults with
                disabilities that are looking Tor a bike e-bike, or a tricycle
                that can suit them. We also get inquiries from a lot of parents
                that maybe have either an adult child or a companion or a
                disabilit function Ike thev used to..
              </Body>
              <Body as="h4" mt="30px" fontWeight="bold">
                READ MORE -
              </Body>
            </Flex>
            <Flex
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              width="50%"
              height="450px"
            >
              <img
                src="https://images.ctfassets.net/ogr4ifihl2yh/2lK5CbjHxR1N0rqXe056ZL/fd39788f7f8050efde227dd26f59e62f/WC5A2131.jpg?w=2000&h=1333&q=85&fm=webp"
                alt="Latest Image"
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </Flex>
          </Flex>
        ))}
      {articles.length > 0 && (
        <>
          <SimpleGrid
            columns={[1, null, null, null, 2, 3]}
            spacingX="1.25rem"
            spacingY="3.625rem"
            mt={bp("1rem", "2rem")}
            mb={bp("3rem", "7.5rem")}
          >
            {articles
              .slice(
                baseZeroPage * pageSize,
                baseZeroPage * pageSize + pageSize
              )
              .map((article) => (
                <ArticleBlockItem
                  key={article.slug}
                  title={article.title!}
                  subtext={truncate(rawRichTextToString(article.excerpt?.raw), {
                    length: 79,
                  })}
                  to={`/blogs/bike-advice/${article.slug}`}
                  image={
                    (article.postImage?.fluid ||
                      placeholderImage) as FluidObject
                  }
                />
              ))}
          </SimpleGrid>
          <Pagination
            width="45.125rem"
            maxWidth="100%"
            mx="auto"
            page={page || 1}
            pageCount={pageCount}
            onNext={handleNext}
            onPrevious={handlePrev}
            mb="2.5rem"
          />
        </>
      )}
    </Box>
  )
}
